@use "./setting/index" as s;

.hesperiden.tparrows {
  display: none;
}
.card-icon-border-large {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-icon-border-large img {
  border-radius: 0;
}
.top-img {
  img {
    width: 100%;
  }
}

.box-width {
  max-width: 760px;
  margin: 0 auto;
}
.box-height {
  height: 158px;
}
#body > div.main-wrapper.home > section.d-none.d-sm-block.section-top > div {
  @include s.responsive(md) {
    max-width: 100%;
  }
}
.point-inner {
  max-width: 900px;
  margin: 0 auto;
}
.point {
  padding: 30px 20px 50px 20px;
  background-image: url(/assets/img/index/point-bg.png);
  background-size: cover;
}
.point-head {
  margin-top: 30px;
  @include s.responsive(sm) {
    margin-top: 0;
  }
  img {
    @include s.responsive(sm) {
      @include s.img-vw(316,576);
    }
  }
}
.point-box {
  border: 1px solid s.$color-blue;
  box-shadow: 0 3px 5px 0 #999;
  width: calc((100% / 2) - (20px * 1 / 2));
  @include s.responsive(sm) {
    width: 100%;
  }
  h3 {
    @include s.font-se;
    font-size: s.rem(22px);
    @include s.responsive(md) {
      font-size: s.rem(16px);
    }
  }
  .font-size-19 {
    font-size: s.rem(19px);
  }
  span {
    color: s.$color-blue;
  }
}

.point-bottom {
  background: #bfc9cf;
  p {
    font-size: s.rem(10px);
    color: #000;
    line-height: 2;
  }
}

.prize {
  background-image: url(/assets/img/index/prize-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 50px 20px;
  p {
    margin-bottom: 0;
  }
  @include s.responsive(sm) {
    padding-top: 30px;
  }
}
.prize-head {
  img {
    width: 250px;
  }
}
.prize-inner {
  @include s.responsive(md) {
    flex-direction: column;
  }
}
.prize-left {
  flex: 1;
  @include s.responsive(md) {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.prize-middle {
  flex: 0.5;
  @include s.responsive(md) {
    order: 1;
  }
}
.prize-right {
  flex: 1;
  @include s.responsive(md) {
    order: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
.prize-box {
  h4 {
    font-size: s.rem(20px);
    color: #fff;
    font-weight: bold;
    margin-bottom: 0;
    @include s.responsive(sm) {
      font-size: s.rem(14px);
    }

  }
  p {
    margin-top: 10px;
    font-size: s.rem(12px);
    color: #fff;
    font-weight: bold;
  }
}

.prize-btn-pc {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  @include s.responsive(md) {
    display: none;
  }
  a {
    color: #fff;
    font-size: s.rem(12px);
  }
}
.prize-btn-sp {
  a {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #fff;
    color: #fff;
    font-size: s.rem(12px);
  }
}

.info {
  padding: 50px 20px 80px 20px;
  @include s.responsive(md) {
    padding: 30px 20px;
  }
}
.info-head {
  img {
    @include s.responsive(sm) {
      @include s.img-vw(500,576);
    }
  }
}
.voice {
  background-image: url(/assets/img/index/voice-bg.png);
  background-size: cover;
  background-position: center center;
}
.voice-head {
  img {
    @include s.responsive(sm) {
      @include s.img-vw(417,576);
    }
  }
}
.voice-top {
  background-color: rgb(100, 100, 100, 0.5);
  padding: 40px 20px 50px 20px;
}
.voice-qa01 {
  display: flex;
  justify-content: flex-end;
  margin-right: 4vw;
  img {
    max-width: 50%;
    @include s.responsive(sm) {
      max-width: 90%;
    }
  }
  @include s.responsive(md) {
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.voice-qa02 {
  img {
    max-width: 50%;
    @include s.responsive(sm) {
      max-width: 90%;
    }
  }
  @include s.responsive(md) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.voice-qa01,
.voice-qa02 {
  width: 50%;
  @include s.responsive(md) {
    width: 100%;
    flex-direction: column;
    img {
      width: 100%;
    }
  }
}

.voice-bottom {
  padding: 50px 20px 80px 20px;
  @include s.responsive(md) {
    padding: 30px 20px;
  }
}

.news-body {
  max-width: 900px;
  margin: 0 auto;
}

.border-top-blue {
  border-top: 5px solid s.$color-blue;
}

.news-card {
  background-color: #BFC1C3;
}
.news-head {
  img {
    @include s.responsive(sm) {
      @include s.img-vw(444,576);
    }
  }
}

.news-p {
  font-size: s.rem(10px);
  @include s.line-clamp(4);
  color: #000;
  @include s.responsive(md) {
    font-size: s.rem(13px);
  }
}
.news-text-head {
  @include s.responsive(md) {
    font-size: s.rem(18px);
  }
}
.news-img {
  @include s.responsive(sm) {
   img {
     height: 127px;
   }
  }
}
.btn-link {
  color:#4D4D4D;
  @include s.responsive(md) {
    padding: 0;
  }
}
.swiper {
  width: 100%;
  display: none;
}
.swiper-wrapper {
  @media (min-width: 577px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.swiper-slide {
  max-width:276px;
  width: calc(31.72% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
  @include s.responsive(sm) {
    max-width:100%;
    img {
      max-width: 276px;
    }
  }
  @media (min-width: 577px) {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.swiper-button-prev {
  left: -5px;
}
.swiper-button-next {
  right: -5px;
}
.swiper-button-prev,
.swiper-button-next {
  background: s.$color-blue;
  width: 50px;
  height: 50px;
  border-radius: 50%;
    @media (min-width: 577px) {
      display: none;
    }
    i {
      font-size: s.rem(40px);
      color: #fff;
    }
}
.swiper-button-next:after, .swiper-button-prev:after {
  display: none;
}
.swiper-container {
  position: relative;
}

.more-btn {
  a {
    background:s.$color-blue;
    color: #fff;
    font-size: s.rem(12px);
    color: #fff;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0px 3px 5px #aaa;
  }
}

