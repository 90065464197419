@function _get_vw($size, $viewport: 375) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

//フォント用のmixin
@mixin font-vw($font_size: 10, $viewsize: 375) {
  font-size: $font_size * 1px;
  font-size: _get_vw($font_size, $viewsize);
}
// //ブラウザサイズが375pxのときに10px相当のvwの指定
//   @include font-vw();
// //ブラウザサイズが768pxのときに、フォントサイズが18pxになるようにvwで指定する場合
//   @include font-vw(18,768);


//画像用のmixin
@mixin img-vw($img_size, $viewsize: 375) {
  width: $img_size * 1px;
  width: _get_vw($img_size, $viewsize);
}
// //ブラウザサイズが375pxのときに、画像サイズが70pxになるようにvwで指定する場合
//   @include img-vw(70);
// //ブラウザサイズが768pxのときに、画像サイズが200pxになるようにvwで指定する場合
//   @include img-vw(200,768);

//paddingやmargin用のmixin（widhtやheightでも使える）
@mixin p-vw($property, $size, $viewsize: 375) {
  #{$property}: $size * 1px;
  #{$property}: _get_vw($size, $viewsize);
}
// //ブラウザサイズが375pxのときに、paddingが20px、marginが10pxになるようにvwで指定する場合
//   @include p-vw(padding, 20);
//   @include p-vw(margin, 10);
// //ブラウザサイズが768pxのときに、paddingが50px、marginが30pxになるようにvwで指定する場合
//   @include p-vw(padding, 50, 768);
//   @include p-vw(margin, 30, 768);




// //ブラウザサイズ1360px以上はフォントサイズを20px
// p{
//   font-size: 20px;
// }

// //ブラウザサイズ769px〜1359pxの間であれば、1360pxのときにフォントサイズが20pxになるようにvwに変換
// @media (min-width: 769px) and (max-width: 1359px){
//  p{
//    @include font-vw(20,1360);
//  }
// }

// //ブラウザサイズ768px以下であれば、375pxのときにフォントサイズが15pxになるようにvwに変換
// @media (max-width: 768px){
//  p{
//    @include font-vw(15,375); // @include font-vw(15);でもOK
//  }
// }