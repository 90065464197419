@mixin line-clamp($count: 3) {
  // 引数が数値以外だったらエラーを返す
  @if type-of($count) != 'number' {
    @error 'line-clampの引数は必ず数値で指定してください';
  }

  @if $count == 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

// .foo {
//   @include line-clamp(1);
// }