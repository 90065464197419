@charset "utf-8";

// placeholder色
$placeholderColor: #c3c3c3;

$color_base: #eee;
$color_main: #e2b527;
$color_sub: #000;
$color_accent: #000;
$color_text: #333;
$color_link: #04c;
$width_sp: 95%;
$width_pc: 960px;
$hover_opacity: 0.7;
$anime_nomal: all 0.2s ease-in-out;

$margin-primary: 100px;


$color-blue: #005AA0;
$color-light-blue: #3297C9;
$color-green: #9CCA45;
$color-page-blue: #dce9ff;

@mixin gothic {
  font-family: YuGothic,'Yu Gothic',YuGothic,'Yu Gothic',sans-serif;
}

@mixin meiryo {
  font-family: 'メイリオ', Meiryo,'ヒラギノ角ゴシック','Hiragino Sans',sans-serif;
}