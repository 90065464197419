@charset "UTF-8";
.hesperiden.tparrows {
  display: none;
}

.card-icon-border-large {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon-border-large img {
  border-radius: 0;
}

.top-img img {
  width: 100%;
}

.box-width {
  max-width: 760px;
  margin: 0 auto;
}

.box-height {
  height: 158px;
}

@media screen and (max-width: 767px) {
  #body > div.main-wrapper.home > section.d-none.d-sm-block.section-top > div {
    max-width: 100%;
  }
}

.point-inner {
  max-width: 900px;
  margin: 0 auto;
}

.point {
  padding: 30px 20px 50px 20px;
  background-image: url(/assets/img/index/point-bg.png);
  background-size: cover;
}

.point-head {
  margin-top: 30px;
}
@media screen and (max-width: 576px) {
  .point-head {
    margin-top: 0;
  }
}
@media screen and (max-width: 576px) {
  .point-head img {
    width: 316px;
    width: 54.8611111111vw;
  }
}

.point-box {
  border: 1px solid #005AA0;
  box-shadow: 0 3px 5px 0 #999;
  width: calc((100% / 2) - (20px * 1 / 2));
}
@media screen and (max-width: 576px) {
  .point-box {
    width: 100%;
  }
}
.point-box h3 {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", sans-serif;
  font-size: 1.375rem;
}
@media screen and (max-width: 767px) {
  .point-box h3 {
    font-size: 1rem;
  }
}
.point-box .font-size-19 {
  font-size: 1.1875rem;
}
.point-box span {
  color: #005AA0;
}

.point-bottom {
  background: #bfc9cf;
}
.point-bottom p {
  font-size: 0.625rem;
  color: #000;
  line-height: 2;
}

.prize {
  background-image: url(/assets/img/index/prize-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 50px 20px;
}
.prize p {
  margin-bottom: 0;
}
@media screen and (max-width: 576px) {
  .prize {
    padding-top: 30px;
  }
}

.prize-head img {
  width: 250px;
}

@media screen and (max-width: 767px) {
  .prize-inner {
    flex-direction: column;
  }
}

.prize-left {
  flex: 1;
}
@media screen and (max-width: 767px) {
  .prize-left {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.prize-middle {
  flex: 0.5;
}
@media screen and (max-width: 767px) {
  .prize-middle {
    order: 1;
  }
}

.prize-right {
  flex: 1;
}
@media screen and (max-width: 767px) {
  .prize-right {
    order: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.prize-box h4 {
  font-size: 1.25rem;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0;
}
@media screen and (max-width: 576px) {
  .prize-box h4 {
    font-size: 0.875rem;
  }
}
.prize-box p {
  margin-top: 10px;
  font-size: 0.75rem;
  color: #fff;
  font-weight: bold;
}

.prize-btn-pc {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
}
@media screen and (max-width: 767px) {
  .prize-btn-pc {
    display: none;
  }
}
.prize-btn-pc a {
  color: #fff;
  font-size: 0.75rem;
}

.prize-btn-sp a {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 0.75rem;
}

.info {
  padding: 50px 20px 80px 20px;
}
@media screen and (max-width: 767px) {
  .info {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 576px) {
  .info-head img {
    width: 500px;
    width: 86.8055555556vw;
  }
}

.voice {
  background-image: url(/assets/img/index/voice-bg.png);
  background-size: cover;
  background-position: center center;
}

@media screen and (max-width: 576px) {
  .voice-head img {
    width: 417px;
    width: 72.3958333333vw;
  }
}

.voice-top {
  background-color: rgba(100, 100, 100, 0.5);
  padding: 40px 20px 50px 20px;
}

.voice-qa01 {
  display: flex;
  justify-content: flex-end;
  margin-right: 4vw;
}
.voice-qa01 img {
  max-width: 50%;
}
@media screen and (max-width: 576px) {
  .voice-qa01 img {
    max-width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .voice-qa01 {
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.voice-qa02 img {
  max-width: 50%;
}
@media screen and (max-width: 576px) {
  .voice-qa02 img {
    max-width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .voice-qa02 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.voice-qa01,
.voice-qa02 {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .voice-qa01,
.voice-qa02 {
    width: 100%;
    flex-direction: column;
  }
  .voice-qa01 img,
.voice-qa02 img {
    width: 100%;
  }
}

.voice-bottom {
  padding: 50px 20px 80px 20px;
}
@media screen and (max-width: 767px) {
  .voice-bottom {
    padding: 30px 20px;
  }
}

.news-body {
  max-width: 900px;
  margin: 0 auto;
}

.border-top-blue {
  border-top: 5px solid #005AA0;
}

.news-card {
  background-color: #BFC1C3;
}

@media screen and (max-width: 576px) {
  .news-head img {
    width: 444px;
    width: 77.0833333333vw;
  }
}

.news-p {
  font-size: 0.625rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000;
}
@media screen and (max-width: 767px) {
  .news-p {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 767px) {
  .news-text-head {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 576px) {
  .news-img img {
    height: 127px;
  }
}

.btn-link {
  color: #4D4D4D;
}
@media screen and (max-width: 767px) {
  .btn-link {
    padding: 0;
  }
}

.swiper {
  width: 100%;
  display: none;
}

@media (min-width: 577px) {
  .swiper-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.swiper-slide {
  max-width: 276px;
  width: calc(31.72% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  width: 100%;
}
@media screen and (max-width: 576px) {
  .swiper-slide {
    max-width: 100%;
  }
  .swiper-slide img {
    max-width: 276px;
  }
}
@media (min-width: 577px) {
  .swiper-slide:not(:last-child) {
    margin-right: 20px;
  }
}

.swiper-button-prev {
  left: -5px;
}

.swiper-button-next {
  right: -5px;
}

.swiper-button-prev,
.swiper-button-next {
  background: #005AA0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
@media (min-width: 577px) {
  .swiper-button-prev,
.swiper-button-next {
    display: none;
  }
}
.swiper-button-prev i,
.swiper-button-next i {
  font-size: 2.5rem;
  color: #fff;
}

.swiper-button-next:after, .swiper-button-prev:after {
  display: none;
}

.swiper-container {
  position: relative;
}

.more-btn a {
  background: #005AA0;
  color: #fff;
  font-size: 0.75rem;
  color: #fff;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0px 3px 5px #aaa;
}